<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    computed: { ...mapGetters(['companys']) },
    data() {
        return {
            savedInMemory: false,
            companyList: [],
            company: null,
            selectedItemFromServer: null,
            selectedProcenaItemFromServer: null,
            mainName: null,
            mainTitle: null,
            mainText: null,
            default: {
                questionText: null,
                isTextAnswer: false,
                isPredetermentAnswers: false,
                answers: [],
                isCheckAnswer: false,
                isSingleCheck: false,
                isNumberAnswer: false,
            },
            defaultProcena: {
                questionText: null,
                isTextAnswer: false,
                isComboAnswer: false,
                isSingleCheck: false,
                answers: [],
            },
            items: [],
            itemsFromServer: [],
            procenaItemsFromServer: [],
            selectedElement: null,
            elements: {
                scaleSatisfy: [
                    { text: "u potpunosti nezadovoljan", bgColor: "bg-danger", txtColor: "text-danger", checked: false },
                    { text: "nezadovoljan", bgColor: "bg-warning", txtColor: "text-warning", checked: false },
                    { text: "delimično zadovoljan", bgColor: "bg-info", txtColor: "text-info", checked: false },
                    { text: "zadovoljan", bgColor: "bg-primary", txtColor: "text-primary", checked: false },
                    { text: "u potpunosti zadovoljan", bgColor: "bg-success", txtColor: "text-success", checked: false },
                ],
                scaleSignificant: [
                    { text: "u potpunosti beznačajan", bgColor: "bg-danger", txtColor: "text-danger", checked: false },
                    { text: "nije značajan", bgColor: "bg-warning", txtColor: "text-warning", checked: false },
                    { text: "delimično značajan", bgColor: "bg-info", txtColor: "text-info", checked: false },
                    { text: "značajan", bgColor: "bg-primary", txtColor: "text-primary", checked: false },
                    { text: "veoma značajan", bgColor: "bg-success", txtColor: "text-success", checked: false },
                ],
                answers: [
                    { checked: false, text: null }
                ],
                answersSingle: [
                    { checked: false, text: null }
                ],
                textArea: { text: null },
                importance: [
                    { number: 0, text: null }
                ]
            },
            index: null,
            savedSuccessfully: false,
            saveFailed: false,
            saveFailedText: null,
            isQuestionnaire: true,
        };

    },
    methods: {
        ...mapActions(['getCompanies', 'setCompanyCustomQuestionnaire', 'setCompanyCustomProcena']),

        async submit() {

            /* Set index value */
            this.companys.forEach(company => {
                if (company.name == this.company && this.isQuestionnaire) {
                    this.index = company.customQuestionnaire.length
                } else if (company.name == this.company && !this.isQuestionnaire) {
                    this.index = company.customProcena.length
                }
            })

            if (this.selectedItemFromServer !== this.mainName && this.mainName && this.selectedProcenaItemFromServer !== this.mainName) {
                let res;
                if (this.isQuestionnaire) {
                    let questionnaireData = {
                        active: false,
                        title: this.mainTitle,
                        name: this.mainName,
                        text: this.mainText,
                        questions: this.items,
                        index: this.index,
                        companyName: this.company
                    };
                    res = await this.setCompanyCustomQuestionnaire(questionnaireData);
                } else if (!this.isQuestionnaire) {
                    let questionnaireData = {
                        active: false,
                        name: this.mainName,
                        title: this.mainTitle,
                        questions: this.items,
                        index: this.index,
                        companyName: this.company
                    };
                    res = await this.setCompanyCustomProcena(questionnaireData);
                }
                if (res) {
                    this.validation = `Sačuvani podaci za kompaniju ${this.company}`;
                    this.savedSuccessfully = true;
                    this.mainName = null;
                    setTimeout(() =>
                        this.savedSuccessfully = false, 5000
                    );
                    await this.getCompanies();
                    this.getSavedQuestionnaires(this.company)
                } else {
                    this.validation = `Neuspelo povezivanje sa bazom (code: PEA_182)`;
                    this.saveFailed = true;
                    setTimeout(() =>
                        this.saveFailed = false, 5000
                    );
                }
            } else {
                this.saveFailedText = "Upitnik sa istim internim nazivom već postoji."
                this.saveFailed = true;
                setTimeout(() => { this.saveFailed = false, this.saveFailedText = null }, 5000
                );
            }


        },
        addToItems() {
            if (this.selectedElement === 'scaleSatisfy') {
                this.default.isPredetermentAnswers = true,
                    this.default.isCheckAnswer = true;
                this.default.isSingleCheck = true;
                this.selectedScaleSatisfyFalse();
                this.elements.scaleSatisfy.forEach(element => {
                    this.default.answers.push(element.text)
                })
            } else if (this.selectedElement === 'scaleSignificant') {
                this.default.isPredetermentAnswers = true,
                    this.default.isCheckAnswer = true;
                this.default.isSingleCheck = true;
                this.selectedScaleSignificantFalse();
                this.elements.scaleSignificant.forEach(element => {
                    this.default.answers.push(element.text)
                })
            } else if (this.selectedElement === 'answer') {
                this.default.isPredetermentAnswers = true,
                    this.default.isCheckAnswer = true;
                this.answerCheckboxReset();
                this.elements.answers.forEach(element => {
                    this.default.answers.push(element.text)
                })
            } else if (this.selectedElement === 'answerSingle') {
                if (this.isQuestionnaire) {
                    this.default.isPredetermentAnswers = true,
                        this.default.isCheckAnswer = true;
                    this.default.isSingleCheck = true;
                    this.answerSingleCheckboxReset();
                    this.elements.answersSingle.forEach(element => {
                        this.default.answers.push(element.text)
                    })
                } else if (!this.isQuestionnaire) {
                    this.defaultProcena.isSingleCheck = true;
                    this.answerSingleCheckboxReset();
                    this.elements.answersSingle.forEach(element => {
                        this.defaultProcena.answers.push(element.text)
                    })
                }
            } else if (this.selectedElement === 'importance') {
                this.default.isPredetermentAnswers = true,
                    this.default.isNumberAnswer = true;
                this.importanceNumberReset();
                this.elements.importance.forEach(element => {
                    this.default.answers.push(element.text)
                })
            } else if (this.selectedElement === 'textArea') {
                if (this.isQuestionnaire) {
                    this.default.isTextAnswer = true
                } else if (!this.isQuestionnaire) {
                    this.defaultProcena.isTextAnswer = true
                }

            } else if (this.selectedElement === 'textSingleCombo') {
                this.defaultProcena.isComboAnswer = true
                this.answerSingleCheckboxReset();
                this.elements.answersSingle.forEach(element => {
                    this.defaultProcena.answers.push(element.text)
                })
            }

            if (this.isQuestionnaire) {
                let json = JSON.parse(JSON.stringify(this.default));
                this.items.push(json)
            } else if (!this.isQuestionnaire) {
                let json = JSON.parse(JSON.stringify(this.defaultProcena));
                this.items.push(json)
            }

            this.resetTemplate();

        },
        removeFromItems(i) {
            this.items.splice(i, 1)
        },
        resetTemplate() {
            this.selectedElement = null;
            this.default.questionText = null;
            this.defaultProcena.questionText = null;
            this.default.isPredetermentAnswers = false,
                this.default.isTextAnswer = false;
            this.defaultProcena.isTextAnswer = false;
            this.default.isSingleCheck = false;
            this.defaultProcena.isSingleCheck = false;
            this.defaultProcena.isComboAnswer = false;
            this.default.answers = [];
            this.defaultProcena.answers = [];
            this.default.isCheckAnswer = false;
            this.default.isNumberAnswer = false;
            this.elements.answers = [{ checked: false, text: null }];
            this.elements.answersSingle = [{ checked: false, text: null }];
            this.selectedScaleSatisfyFalse();
            this.selectedScaleSignificantFalse();
            this.elements.importance = [{ number: 0, text: null }]
            this.elements.textArea.text = null;
        },
        removeAll() {
            this.items = [];
        },
        selectedScaleSatisfyFalse() {
            this.elements.scaleSatisfy.forEach(item => {
                item.checked = false;
            })
        },
        checkOnlyOneSatisfy(index) {
            for (let i = 0; i < this.elements.scaleSatisfy.length; i++) {
                if (i == index) {
                    this.elements.scaleSatisfy[i].checked = true;
                } else {
                    this.elements.scaleSatisfy[i].checked = false;
                }
            }
        },
        selectedScaleSignificantFalse() {
            this.elements.scaleSignificant.forEach(item => {
                item.checked = false;
            })
        },
        checkOnlyOneSignificant(index) {
            for (let i = 0; i < this.elements.scaleSignificant.length; i++) {
                if (i == index) {
                    this.elements.scaleSignificant[i].checked = true;
                } else {
                    this.elements.scaleSignificant[i].checked = false;
                }
            }
        },
        addAnswer() {
            this.elements.answers.push({ checked: false, text: null })
        },
        removeAnswer(i) {
            this.elements.answers.splice(i, 1)
        },
        answerCheckboxReset() {
            this.elements.answers.forEach(element => {
                element.checked = false;
            })
        },
        addImportance() {
            this.elements.importance.push({ number: 0, text: null })
        },
        removeImportance(i) {
            this.elements.importance.splice(i, 1)
        },
        importanceNumberReset() {
            this.elements.importance.forEach(element => {
                element.number = 0;
            })
        },
        addAnswerSingle() {
            this.elements.answersSingle.push({ checked: false, text: null })
        },
        removeAnswerSingle(i) {
            this.elements.answersSingle.splice(i, 1)
        },
        answerSingleCheckboxReset() {
            this.elements.answersSingle.forEach(element => {
                element.checked = false;
            })
        },
        checkOnlyOneAnswerSingle(index) {
            for (let i = 0; i < this.elements.answersSingle.length; i++) {
                if (i == index) {
                    this.elements.answersSingle[i].checked = true;
                } else {
                    this.elements.answersSingle[i].checked = false;
                }
            }
        },
        moveUp(i) {
            if (i > 0) {
                let temp = this.items[i - 1]
                this.items[i - 1] = this.items[i]
                this.items[i] = temp
            }
        },
        moveDown(i) {
            if (i + 1 < this.items.length) {
                let temp = this.items[i + 1]
                this.items[i + 1] = this.items[i]
                this.items[i] = temp
            }
        },
        getSavedQuestionnaires(companyDropdown) {
            this.selectedItemFromServer = null;
            this.selectedProcenaItemFromServer = null;
            this.mainName = null;
            this.companys.forEach(company => {
                if (company.name == companyDropdown) {
                    this.itemsFromServer = company.customQuestionnaire
                    this.procenaItemsFromServer = company.customProcena
                }
            });
        },
        getSelectedItemFromServer(selectedItemFromServer) {
            this.isQuestionnaire = true;
            this.selectedProcenaItemFromServer = null;
            this.itemsFromServer.forEach(item => {
                if (item.name == selectedItemFromServer) {
                    this.items = item.questions;
                    this.mainTitle = item.title;
                    this.mainText = item.text;
                    this.mainName = item.name
                }
            });
        },
        getSelectedProcenaItemFromServer(selectedProcenaItemFromServer) {
            this.isQuestionnaire = false;
            this.selectedItemFromServer = null;
            this.procenaItemsFromServer.forEach(item => {
                if (item.name == selectedProcenaItemFromServer) {
                    this.items = item.questions;
                    this.mainTitle = item.title;
                    this.mainName = item.name
                }
            });
        },
        activateQuestionnaire(boolean) {
            this.items = [];
            this.resetTemplate();
            this.mainName= null;
            this.selectedItemFromServer = null;
            this.selectedProcenaItemFromServer = null;
            if (boolean) {
                this.isQuestionnaire = true;
                this.mainTitle = null;
            } else if (!boolean) {
                this.isQuestionnaire = false;
                this.mainTitle = 'Procena Kandidata';
            }
        }


    },
    async created() {
        await this.getCompanies();
        this.companys.forEach(company => {
            if (company.name != "admin") {
                this.companyList.push(company.name);
            }

        });

    },
}
</script>
<template>
    <div class="mt-2 text-center">
        <h2>Generator upitnika</h2>
        <div class="d-flex justify-content-center mb-3">
            <label class="pt-2 fw-bold p-0 m-0 text-nowrap">Za kompaniju:
                <select @change="getSavedQuestionnaires(company)" name="type" id="type" v-model="company">
                    <option v-for="(company, i) in companyList" :key="i">{{ company }}</option>
                </select>
            </label>
        </div>

    </div>
    <div class="d-flex row">
    <!-- Questionnaire dropdown -->
    <div v-if="itemsFromServer && company" class="col-md-6 d-flex justify-content-center pb-4">
        <label v-if="itemsFromServer.length > 0" class="pt-2 fw-bold p-0 m-0 text-nowrap">Postojeći upitnici:
            <select @change="getSelectedItemFromServer(selectedItemFromServer)" name="type" id="type"
                v-model="selectedItemFromServer">
                <option :class="item.active ? 'cc' : 'bb'" v-for="(item, i) in itemsFromServer" :key="i">{{ item.name }}
                </option>
            </select>
        </label>
        <p v-if="itemsFromServer.length == 0">Ne postoje snimljeni <span class="text-danger fw-bold">upitnici</span> za izabranu kompaniju!</p>
    </div>
    <!-- Procena dropdown -->
    <div v-if="procenaItemsFromServer && company" class="col-md-6 d-flex justify-content-center pb-4">
        <label v-if="procenaItemsFromServer.length > 0" class="pt-2 fw-bold p-0 m-0 text-nowrap">Postojeće procene:
            <select @change="getSelectedProcenaItemFromServer(selectedProcenaItemFromServer)" name="type" id="type"
                v-model="selectedProcenaItemFromServer">
                <option :class="item.active ? 'cc' : 'bb'" v-for="(item, i) in procenaItemsFromServer" :key="i">{{ item.name }}
                </option>
            </select>
        </label>
        <p v-if="procenaItemsFromServer.length == 0">Ne postoje snimljene <span class="text-danger fw-bold">procene</span> za izabranu kompaniju!</p>
    </div></div>
    <div class="border border-dark border-2 rounded-3 questions-area">
        <div class="d-grid p-3">
            <input v-model="this.mainName" type="text" maxlength="30"
                placeholder="Unesi interni unikatni naziv za ovaj upitnik, koji ce se prikazivati u opadajucem meniju. Max 30 karaktera.'" />
            <input v-model="this.mainTitle" type="text" :disabled="!isQuestionnaire"
                placeholder="Unesi naslov, npr: 'Istraživanje stavova zaposlenih o benefitima kompanije [ime kompanije]'" />
            <textarea :rows="5" v-model="this.mainText" v-if="isQuestionnaire"
                placeholder="Unesi tekst, npr: 'Poštovani/a, Učestvujete u istraživanju stavova zaposlenih...'"></textarea>
            <span v-if="!isQuestionnaire">Godište:</span>
            <span v-if="!isQuestionnaire">Prebivalište:</span>
            <span v-if="!isQuestionnaire">Pozicija:</span>
        </div>
        <div class="d-grid p-3">
            <h4 class="mx-5 text-center">{{ this.mainTitle }}</h4>
            <textarea v-if="this.mainText && isQuestionnaire" :rows="5" v-model="this.mainText" class="mx-5 textarea-invisible"></textarea>
        </div>
        <hr class="mx-4 my-1" />
        <div v-for="(item, i) in items" :key="i" class="d-grid p-3">

            <span class="ps-4 mb-3">
                <button
                    :class="i + 1 < items.length ? 'bg-warning border-warning text-white' : 'border-warning text-warning'"
                    @click="moveDown(i)" class="down border border-warning fw-boldt">˅</button>
                <button :class="i > 0 ? 'bg-warning border-warning text-white' : 'border-warning text-warning'"
                    @click="moveUp(i)" class="up border border-warning fw-bold">˄</button>
            </span>
            <p class="ms-5 ps-3">{{ item.questionText }}</p>

            <!-- RENDER Questionnaire Only -->
            <div v-if="item.isPredetermentAnswers" class="px-3 mx-5 d-flex justify-content-left">
                <div class="d-grid">
                    <div v-for="(answer, i) in item.answers" :key="i" class="d-inline-flex">
                        <div class="d-flex align-items-center mx-2">
                            <input v-if="item.isCheckAnswer" disabled type="checkbox"
                                class="border border-dark scale-button" />
                            <input v-if="item.isNumberAnswer" class="number-input" type="number" :min="0" disabled />
                            <span class="ms-2">{{ answer }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- RENDER END -->
            <!-- RENDER Procena Only -->
            <div v-if="item.isSingleCheck" class="px-3 mx-5 d-flex justify-content-left">
                <div class="d-flex">
                    <div v-for="(answer, i) in item.answers" :key="i" class="d-inline-flex">
                        <div class="d-flex align-items-center mx-2">
                            <input disabled type="checkbox" class="border border-dark scale-button" />
                            <span class="ms-2">{{ answer }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- RENDER END -->
            <!-- RENDER Text and Single COMBO -->
            <div v-if="item.isComboAnswer">
                <div v-for="(answer, i) in item.answers" :key="i" class="d-inline-flex">
                    <div class="d-flex align-items-center mx-2">
                        <input disabled type="checkbox" class="border border-dark scale-button" />
                        <span class="ms-2">{{ answer }}</span>
                    </div>
                </div>
                <div class="d-grid">
                    <textarea rows="3" disabled></textarea>
                </div>
            </div>
            <!-- RENDER Text and Single COMBO END-->
            <!-- RENDER Text Area -->
            <div v-if="item.isTextAnswer" class="border my-3 mx-5">
                <div class="d-grid">
                    <textarea rows="3" disabled></textarea>
                </div>
            </div>
            <!-- RENDER Text Area END -->

            <div class="mt-2 pe-4">
                <button @click="removeFromItems(i)" class="ms-2 btn btn-outline-danger px-5 float-end">Ukloni</button>
            </div>
            <hr v-if="i + 1 < items.length" class="mx-4 my-2" />
        </div>
    </div>
    <div class="py-2 d-flex">
        <button v-if="items.length > 0" @click="submit()" class="btn btn-success float-start px-5">Snimi
            upitnik</button>
        <button v-if="items.length > 1" @click="removeAll()" class="btn btn-danger float-end px-5 ms-2">Ukloni sve iz
            upitnika</button>
    </div>
    <!------------------------------------------- GENERATOR ------------------------------------------->
    <div class="mt-5 d-flex">
        <button @click="activateQuestionnaire(true)" class="btn px-3"
            :class="isQuestionnaire ? 'btn-success' : 'btn-outline-success'"><span v-if="isQuestionnaire">UPITNIK
                AKTIVAN</span><span v-if="!isQuestionnaire">AKTIVIRAJ UPITNIK</span></button>
        <button @click="activateQuestionnaire(false)" class="btn px-3 "
            :class="isQuestionnaire ? 'btn-outline-success' : 'btn-success'"><span v-if="!isQuestionnaire">PROCENA
                AKTIVNA</span><span v-if="isQuestionnaire">AKTIVIRAJ PROCENU</span></button>
    </div>
    <div class="border border-dark border-5 rounded d-grid p-3 mb-5 elements-area">
        <textarea v-if="isQuestionnaire" class="mx-5" v-model="this.default.questionText"
            placeholder="Tekst pitanja, npr: '1. Ocenite Vaše zadovoljstvo benefitima'"></textarea>
        <textarea v-if="!isQuestionnaire" class="mx-5" v-model="this.defaultProcena.questionText"
            placeholder="Tekst pitanja, npr: '1. Stepen poznavanja engleskog jezika'"></textarea>
        <div class="mt-4 text-center">
            <h4 class="fw-bold">Izaberi jedan od dole ponudjenih elemenata. Element sa crvenim okvirom je izabrani element.
            </h4>
        </div>
        <!-- QUESTIONNAIRE CHOICES -->
        <div v-if="isQuestionnaire">
            <div class="d-flex row">
                <!-- Scale Satisfy-->
                <div class="px-3 col-md-4 ">
                <div @click="selectedElement = 'scaleSatisfy'"
                    :class="selectedElement == 'scaleSatisfy' ? 'border-danger border-5 bg-warning' : 'border-dark border-2 element-bg'"
                    class="border rounded-3 py-3 px-3 my-3 pointer">
                    <p class="text-center fw-bold">Skala Zadovoljstva</p>
                    <div class="d-grid">
                        <div v-for="(scale, i) in elements.scaleSatisfy" :key="i" class="d-inline-flex">
                            <div class="d-flex align-items-center mx-2 pointer">
                                <input @click="checkOnlyOneSatisfy(i)" type="checkbox" v-model="scale.checked"
                                    class="border border-dark scale-button" />
                                <span class="ms-2">{{ scale.text }}</span>
                            </div>
                        </div>
                    </div>
                </div></div>
                <!-- Scale Satisfy END-->
                <!-- Scale Significant-->
                <div class="px-3 col-md-4 ">
                <div @click="selectedElement = 'scaleSignificant'"
                    :class="selectedElement == 'scaleSignificant' ? 'border-danger border-5 bg-warning' : 'border-dark border-2 element-bg'"
                    class="border rounded-3 py-3 px-3 my-3 pointer">
                    <p class="text-center fw-bold">Skala Značajnosti</p>
                    <div class="d-grid">
                        <div v-for="(scale, i) in elements.scaleSignificant" :key="i" class="d-inline-flex">
                            <div class="d-flex align-items-center mx-2 pointer">
                                <input @click="checkOnlyOneSignificant(i)" type="checkbox" v-model="scale.checked"
                                    class="border border-dark scale-button" />
                                <span class="ms-2">{{ scale.text }}</span>
                            </div>
                        </div>
                    </div>
                </div></div>
                <!-- Scale Significant END-->
                <!-- Text Area -->
                <div class="px-3 col-md-4 ">
                <div @click="selectedElement = 'textArea'"
                    :class="selectedElement == 'textArea' ? 'border-danger border-5 bg-warning' : 'border-dark border-2 element-bg'"
                    class="border rounded-3 py-3 px-3 my-3 pointer">
                    <p class="text-center fw-bold">Element sa mogućnošću da zaposleni upiše komentar</p>
                    <div class="d-grid">
                        <textarea v-model="elements.textArea.text"></textarea>
                    </div>
                </div></div>
                <!-- Text Area END-->
            </div>            
            <!-- Answers -->
            <div @click="selectedElement = 'answer'"
                :class="selectedElement == 'answer' ? 'border-danger border-5 bg-warning' : 'border-dark border-2 element-bg'"
                class="border rounded-3 py-3 px-3 my-3 pointer">
                <p class="text-center fw-bold">Element sa mogućnošću izbora VIŠE odgovora</p>
                <div class="d-grid">
                    <div v-for="(answer, i) in elements.answers" :key="i">
                        <input v-if="answer" class="scale-button" type="checkbox" v-model="answer.checked" />
                        <input v-if="answer" class="ms-2  my-1 w-75" placeholder="Ponudjeni odgovor, npr: 'DA'" type="text"
                            v-model="answer.text" />
                        <button v-if="i + 1 == elements.answers.length" class="btn btn-sm btn-outline-primary ms-2"
                            @click="addAnswer()">Dodaj</button>
                        <button v-if="i + 1 < elements.answers.length" class="btn btn-sm btn-outline-danger ms-2"
                            @click="removeAnswer(i)">Ukloni</button>
                    </div>
                </div>
            </div>
            <!-- Answers END-->
            <!-- Answers SINGLE-->
            <div @click="selectedElement = 'answerSingle'"
                :class="selectedElement == 'answerSingle' ? 'border-danger border-5 bg-warning' : 'border-dark border-2 element-bg'"
                class="border rounded-3 py-3 px-3 my-3 pointer">
                <p class="text-center fw-bold">Element sa mogućnošću izbora SAMO JEDNOG odgovora</p>
                <div class="d-grid">
                    <div v-for="(answer, i) in elements.answersSingle" :key="i">
                        <input v-if="answer" @click="checkOnlyOneAnswerSingle(i)" class="scale-button" type="checkbox"
                            v-model="answer.checked" />
                        <input v-if="answer" class="ms-2  my-1 w-75" placeholder="Ponudjeni odgovor, npr: 'DA'" type="text"
                            v-model="answer.text" />
                        <button v-if="i + 1 == elements.answersSingle.length" class="btn btn-sm btn-outline-primary ms-2"
                            @click="addAnswerSingle()">Dodaj</button>
                        <button v-if="i + 1 < elements.answersSingle.length" class="btn btn-sm btn-outline-danger ms-2"
                            @click="removeAnswerSingle(i)">Ukloni</button>
                    </div>
                </div>
            </div>
            <!-- Answers SINGLE END-->
            <!-- Importance -->
            <div @click="selectedElement = 'importance'"
                :class="selectedElement == 'importance' ? 'border-danger border-5 bg-warning' : 'border-dark border-2 element-bg'"
                class="border rounded-3 py-3 px-3 my-3 pointer">
                <p class="text-center fw-bold">Element sa mogućnošću dodeljivanja brojeva svakom odgovoru</p>
                <div class="d-grid">
                    <div v-for="(answer, i) in elements.importance" :key="i">
                        <input v-if="answer" class="" type="number" v-model="answer.number" :min="0"
                            :max="elements.importance.length" />
                        <input v-if="answer" class="ms-2  my-1 w-75" placeholder="Ponudjeni odgovor, npr: 'DA'" type="text"
                            v-model="answer.text" />
                        <button v-if="i + 1 == elements.importance.length" class="btn btn-sm btn-outline-primary ms-2"
                            @click="addImportance()">Dodaj</button>
                        <button v-if="i + 1 < elements.importance.length" class="btn btn-sm btn-outline-danger ms-2"
                            @click="removeImportance(i)">Ukloni</button>
                    </div>
                </div>
            </div>
            <!-- Importance END-->
        </div>

        <!-- PROCENA CHOICES -->
        <div v-if="!isQuestionnaire">
            <div class="d-flex row">
                <!-- Text Area -->
                <div class="col-md-4 px-3">
                <div @click="selectedElement = 'textArea'"
                    :class="selectedElement == 'textArea' ? 'border-danger border-5 bg-warning' : 'border-dark border-2 element-bg'"
                    class="border rounded-3 py-3 px-3 my-3 pointer">
                    <p class="text-center fw-bold">Element sa mogućnošću upisivanja komentar</p>
                    <div class="d-grid">
                        <textarea v-model="elements.textArea.text"></textarea>
                    </div>
                </div></div>
                <!-- Text Area -->

                <!-- Combo -->
                <div class="col-md-8 px-3">
                <div @click="selectedElement = 'textSingleCombo'"
                    :class="selectedElement == 'textSingleCombo' ? 'border-danger border-5 bg-warning' : 'border-dark border-2 element-bg'"
                    class="border rounded-3 py-3 px-3 my-3 pointer">
                    <p class="text-center fw-bold">Element sa kombinacijom teksta i izbora SAMO JEDNOG odgovora</p>
                    <div class="d-grid w-100">
                        <div v-for="(answer, i) in elements.answersSingle" :key="i">
                            <input v-if="answer" @click="checkOnlyOneAnswerSingle(i)" class="scale-button" type="checkbox"
                                v-model="answer.checked" />
                            <input v-if="answer" class="ms-2  my-1 w-75" placeholder="Ponudjeni odgovor, npr: 'DA'"
                                type="text" v-model="answer.text" />
                            <button v-if="i + 1 == elements.answersSingle.length"
                                class="btn btn-sm btn-outline-primary ms-2" @click="addAnswerSingle()">Dodaj</button>
                            <button v-if="i + 1 < elements.answersSingle.length" class="btn btn-sm btn-outline-danger ms-2"
                                @click="removeAnswerSingle(i)">Ukloni</button>
                        </div>
                    </div>
                    <div class="d-grid w-100">
                        <textarea v-model="elements.textArea.text"></textarea>
                    </div>
                </div></div>
            </div>
            <!-- Combo END-->
            <!-- Answers SINGLE-->
            <div @click="selectedElement = 'answerSingle'"
                :class="selectedElement == 'answerSingle' ? 'border-danger border-5 bg-warning' : 'border-dark border-2 element-bg'"
                class="border rounded-3 py-3 px-3 my-3 pointer">
                <p class="text-center fw-bold">Element sa mogućnošću izbora SAMO JEDNOG odgovora</p>
                <div class="d-grid">
                    <div v-for="(answer, i) in elements.answersSingle" :key="i">
                        <input v-if="answer" @click="checkOnlyOneAnswerSingle(i)" class="scale-button" type="checkbox"
                            v-model="answer.checked" />
                        <input v-if="answer" class="ms-2  my-1 w-75" placeholder="Ponudjeni odgovor, npr: 'DA'" type="text"
                            v-model="answer.text" />
                        <button v-if="i + 1 == elements.answersSingle.length" class="btn btn-sm btn-outline-primary ms-2"
                            @click="addAnswerSingle()">Dodaj</button>
                        <button v-if="i + 1 < elements.answersSingle.length" class="btn btn-sm btn-outline-danger ms-2"
                            @click="removeAnswerSingle(i)">Ukloni</button>
                    </div>
                </div>
            </div>
            <!-- Answers SINGLE END-->
        </div>

        <div class="mt-2 d-flex">
            <button @click="addToItems()" class="btn btn-primary px-5">Dodaj u upitnik</button>
            <button @click="resetTemplate()" class="ms-2 btn btn-warning px-5">Reset</button>
        </div>
    </div>
    <div v-if="this.savedSuccessfully" class="bg-success p-3 rounded mb-3 text-white text-center fw-bold fixed-top py-5 ">
        Promene uspešno snimljene!</div>
    <div v-if="this.saveFailed" class="bg-danger p-3 rounded mb-3 text-white text-center fw-bold fixed-top py-5 ">{{
        this.saveFailedText }} Snimanje
        nije uspelo!</div>
</template>
<style scoped>
.pointer {
    cursor: pointer;
}

.elements-area {
    background-color: #85FFBD;
    background-image: linear-gradient(45deg, #85FFBD 0%, #e3ff99 100%);
}

.questions-area {
    background-color: #FFDEE9;
    background-image: linear-gradient(0deg, #FFDEE9 0%, #B5FFFC 100%);
}

.scale-button {
    width: 15px;
    height: 15px;
}

.textarea-invisible {
    border: none;
    background-color: transparent;
    resize: none;
}

.element-bg {
    background-image: radial-gradient(circle farthest-corner at 50.7% 54%, rgba(204, 254, 152, 1) 0%, rgba(229, 253, 190, 1) 92.4%);
}

.down {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.up {
    margin-left: 1px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.number-input {
    width: 60px;
}

.bb {
    background-color: #ffaaaa;
}

.cc {
    background-color: #aaffaa;
}
</style>